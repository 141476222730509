import ALL from "./ALL";

const otherComponents = {
  base: ["A", "W", "R", "S"],
  height: ALL,
  casters: ALL,
  control: [
    "T",
    "FFAC",
    "E3",
    "EXE",
    "T:SLD",
    "FFAC:SLD",
    "EXE:SLD",
    "S",
    "Z",
    "P",
    "FA",
    "X",
    "L",
    "IP",
  ],
  footring: ALL,
  arm: ALL,
  performance: ALL,
  vinylUnderwrap: ALL,
  memoryFoam: ALL,
};

export default [
  {
    code: "X",
    name: "View All",
    seat: ALL,
    back: ALL,
    ...otherComponents,
    base: ALL,
    control: ALL,
  },
  {
    code: "MVMT",
    name: "MVMT Pro",
    description: `The MVMT Pro design is created for today’s ultra-critical environments including static sensitive and particle- and microbe-controlled areas.`,
    seat: ["1"],
    back: ["MB", "TB"],
    ...otherComponents,
    base: ["A", "W", "R"],
    control: ["PM"],
    style: ["MVMT"],
  },
  {
    code: "MTCL",
    name: "MVMT Tech Classic",
    description: `Like all MVMT seating, the Tech C5 Series offer supremely comfortable ergonomic seating with the simplicity of our classic seat controls. They are great to use in clean room, static control/ESD, industrial, laboratory, and healthcare environments.`,
    seat: ["1", "1F", "1B"],
    back: ["MB", "TB"],
    ...otherComponents,
    base: ["A", "W", "R"],
    style: ["MVMT"],
  },
  {
    code: "MTHD",
    name: "MVMT Tech HD",
    description: `Sharing the best attributes of the award-winning MVMT family, the Tech Series with Heavy-duty (HD) base models add a rugged tubular steel base, making them a leading choice for today’s industrial and technical workplaces.`,
    seat: ["1F", "1B"],
    back: ["MB", "TB"],
    ...otherComponents,
    base: ["S", "TUH"],
    style: ["MVMT"],
  },
  // {
  //   code: "AE",
  //   name: "Avenue",
  //   description: `Avenue Series models feature saddle-shaped seats and ergonomic backrests for comfort and productivity, and a range of 5-star base and component options to meet your needs and budget.`,
  //   seat: ["A"],
  //   back: ["E", "B"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  {
    code: "AM",
    name: "Amherst (Avenue)",
    description: `Amherst Series models feature a larger saddle-shaped seat and backrest that add up to enhanced user performance and offer a variety of options to suit your work environment and budget needs.`,
    seat: ["A", "B", "E"],
    back: ["M"],
    style: ["foundation"],
    ...otherComponents,
  },
  // {
  //   code: "BE",
  //   name: "Belize",
  //   description: `The Belize Series offers the right chair for multiple work environments—models feature ergonomic functionality for long days and durability for long- term performance.`,
  //   seat: ["B"],
  //   back: ["E", "M", "B"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  {
    code: "BT",
    name: "Bridgeport (Belize)",
    description: `Ergonomic functionality, a streamlined backrest and a variety of options to meet user needs make Bridgeport Series chairs a popular solution for multiple workspaces.`,
    seat: ["B", "E", "A"],
    back: ["T"],
    style: ["foundation"],
    ...otherComponents,
  },
  {
    code: "EE",
    name: "Elite (Eton)",
    description: `Built to meet demands of the most technical facilities, functional design, a wide range of options and versatility make Elite Series chairs ideal for a variety of work environments.`,
    seat: ["E", "B", "A"],
    back: ["E"],
    style: ["foundation"],
    ...otherComponents,
  },
  {
    code: "EB",
    name: "Embark",
    description: `When cool is crucial, the Embark mesh-back chair back provides maximum ventilation and total comfort.`,
    seat: ["E", "B", "A"],
    back: ["B"],
    style: ["foundation"],
    upholstery: ["P28605"],
    ...otherComponents,
  },
  // {
  //   code: "ET",
  //   name: "Eton",
  //   description: `A wide array of options allows Eton Series models to be configured for a range of workspaces. Standard features include a streamlined backrest for enhanced user motion.`,
  //   seat: ["E"],
  //   back: ["T", "M", "B"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  // {
  //   code: "FC",
  //   name: "Sewn Seams FC",
  //   description: `Models in the FC Series are highlighted by sewn seams upholstery, a technique which minimizes foam compression in the seat and backrest, enhancing the chair's aesthetic profile while delivering long-term user comfort.`,
  //   seat: ["F"],
  //   back: ["C"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  // {
  //   code: "FL",
  //   name: "Sewn Seams FL",
  //   description: `FL Series models are highlighted by a larger seat and upholstery with sewn seams to minimize cushion foam compression – enhancing comfort, performance and looks.`,
  //   seat: ["F"],
  //   back: ["L"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  // {
  //   code: "HC",
  //   name: "Sewn Seams HC",
  //   description: `HC Series models combine Upholstery with sewn seams, a larger backrest and a four-way contoured seat to deliver enhanced user comfort throughout the workday.`,
  //   seat: ["H"],
  //   back: ["C"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  // {
  //   code: "HL",
  //   name: "Sewn Seams HL",
  //   description: `HL Series seating features upholstery with sewn seams to minimize cushion foam compression – enhancing comfort, performance and looks.`,
  //   seat: ["H"],
  //   back: ["L"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  // {
  //   code: "VF:FC",
  //   name: "Vacuum Formed FC",
  //   description: `The FC Vacuum Formed Series is highlighted by seamless, vacuum-formed upholstery to reduce liquid and bacterial infiltration. Customizable with numerous options. `,
  //   seat: ["VF:F"],
  //   back: ["VF:C"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  // {
  //   code: "VF:FL",
  //   name: "Vacuum Formed FL",
  //   description: `Highlighted by seamless, vacuum-formed upholstery to reduce liquid and bacterial infiltration, the Vacuum Formed FL Series also offers a smaller backrest to maximize elbow room.`,
  //   seat: ["VF:F"],
  //   back: ["VF:L"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  // {
  //   code: "VF:HC",
  //   name: "Vacuum Formed HC",
  //   description: `The HC Vacuum Formed Series is highlighted by seamless, vacuum-formed upholstery to reduce liquid and bacterial infiltration. Customizable with numerous options. `,
  //   seat: ["VF:H"],
  //   back: ["VF:C"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  // {
  //   code: "VF:HL",
  //   name: "Vacuum Formed HL",
  //   description: `Highlighted by seamless, vacuum-formed upholstery to reduce liquid and bacterial infiltration, the Vacuum Formed HL Series also offers a smaller backrest to maximize elbow room.`,
  //   seat: ["VF:H"],
  //   back: ["VF:L"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  {
    code: "Z",
    name: "Zephyr",
    description: `Zephyr Series chairs feature an airy mesh back and choice of comfort-enhancing saddle-shaped seats that provide an executive experience for users the entire workday and beyond.`,
    seat: ["A", "F"],
    back: ["P"],
    style: ["foundation"],
    ...otherComponents,
    height: ["L", "M"],
    control: ["Z"],
  },
  {
    code: "GG",
    name: "ArmorSeat",
    description: `Models in the rugged multipurpose ArmorSeat Series feature antimicrobial properties and a UV inhibitor standard in every seat and backrest. Versatile and easy to clean.`,
    seat: ["G"],
    back: ["G"],
    style: ["foundation"],
    ...otherComponents,
  },
  {
    code: "UU",
    name: "UniqueU",
    description: `Durable UniqueU Series chairs have easy-to-clean seats and backrests that resist water, chemicals, paints and more. Highly versatile, they are available in configurations ranging from harsh work environments to ISO 6 clean rooms.`,
    seat: ["U"],
    back: ["U"],
    style: ["foundation"],
    ...otherComponents,
  },
  // {
  //   code: "KX",
  //   name: "Skoop",
  //   description: `An edgy alternative to traditional multipurpose seating, Skoop Series models combine vivid color and contour with ergonomic performance and LEED® compliance.`,
  //   seat: ["K"],
  //   back: ["X"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  {
    code: "WW",
    name: "Willow",
    description: `Chairs in the Willow Series are constructed of naturally beautiful and durable genuine American maple, combining proven functionality with elegant design.`,
    seat: ["W"],
    back: ["W"],
    style: ["foundation"],
    ...otherComponents,
  },
  {
    code: "IP",
    name: "Intensive Plus",
    description: `Highlighted by a 500-lb. capacity and executive-style comfort, Intensive Plus seating is designed for continuous, 24/7/365 use and backed by a 5-year warranty.`,
    seat: ["F"],
    back: ["S"],
    style: ["foundation"],
    ...otherComponents,
    base: ["P"],
    height: ["M"],
    casters: ["IPR", "HG"],
    control: ["IP"],
    footring: ["XF"],
    arm: ["ISAS", "XA"],
    performance: ["06"],
  },
  {
    code: "2A",
    name: "Aramis 2A",
    description: `Long a popular choice in the healthcare market, these stools offer hands-free seat height adjustment.`,
    seat: ["R", "VF:R"],
    back: ["X"],
    style: ["foundation"],
    base: ["C"],
    control: ["FA"],
    height: ["L", "M"],
    casters: ["CRC"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["C"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
  },
  {
    code: "RX",
    name: "Rexford",
    description: `Stools in Rexford Series provide a nimble and ergonomic seating solution in a variety of work and learning environments.`,
    seat: ["R", "VF:R"],
    back: ["X"],
    style: ["foundation"],
    ...otherComponents,
    control: ["S"],
  },
  // {
  //   code: "VF:RX",
  //   name: "Rexford Vacuum Formed",
  //   description: `Vacuum formed upholstery to reduce liquid and bacterial infiltration makes models from this stool series a smart choice for medical and laboratory settings.`,
  //   seat: ["VF:R"],
  //   back: ["X"],
  //   style: ["foundation"],
  //   ...otherComponents,
  //   control: ["S"],
  // },
  {
    code: "RT",
    name: "Regent",
    description: `The addition of a streamlined backrest to RX Regent Series upholstered ergonomic stools makes them a comfortable option for work and education spaces.`,
    seat: ["R", "VF:R"],
    back: ["T"],
    style: ["foundation"],
    ...otherComponents,
  },
  // {
  //   code: "VF:RT",
  //   name: "Regent Vacuum Formed",
  //   description: `Vacuum formed upholstery to reduce liquid and bacterial infiltration combined with a trapezoid-shaped backrest for added ergonomic support make stools in this series an ideal choice for lab and healthcare settings and more.`,
  //   seat: ["VF:R"],
  //   back: ["T"],
  //   style: ["foundation"],
  //   ...otherComponents,
  // },
  {
    code: "TX",
    name: "Traxx",
    description: `Highlighted by an 14-gauge steel seat, stools in the Traxx Series are designed to handle tough industrial settings yet can be configured for use in clean rooms.`,
    seat: ["T"],
    back: ["X"],
    style: ["foundation"],
    ...otherComponents,
    casters: ["RC", "RK", "HG"],
  },
  {
    code: "CX",
    name: "Cerex",
    description: `A rugged self-skinned urethane seat and range of configuration options make stools in the Cerex Series ideal for workplaces from factories to clean rooms.`,
    seat: ["C"],
    back: ["X"],
    style: ["foundation"],
    ...otherComponents,
  },
  {
    code: "GX",
    name: "LX Sit/Stand",
    description: `LX Series Sit/Stands offer a durable solution that provides torso and hip support for workers who must stand for prolonged periods to get the job done.`,
    seat: ["L"],
    back: ["X"],
    style: ["foundation"],
    ...otherComponents,
  },
  {
    code: "AJ",
    name: "AJ Stool",
    description: `Stools in this series feature iconic workplace seating design, backed by the BioFit tradition of durable construction and reliability – shift after shift, year after year. Available in 18", 24" and 30" heights.`,
    seat: ["TX"],
    back: ["X"],
    style: ["foundation"],
    ...otherComponents,
    base: ["A18", "A24", "A30"],
  },
  {
    code: "TXG",
    name: "TXG Stool",
    description: `Available in two seat height ranges, the TXG stool delivers tough performance for small spaces. It’s compact, sturdy and built to last.`,
    seat: ["T"],
    back: ["X"],
    style: ["foundation"],

    ...otherComponents,
    footring: ["XF"],
    performance: ["06", "C", "ISO4"],
    height: ["L", "M"],
    casters: ["GL"],
    base: ["G"],
  },
  {
    code: "Scepter",
    name: "Scepter",
    description: `Scepter seating delivers performance and ergonomic functionality to outfit the corner office or your entire organization. High contoured backrest provides lumbar support and built-in headrest.`,
    seat: ["A"],
    back: ["S"],
    style: ["foundation"],
    ...otherComponents,
    base: ["R", "P"],
    height: ["L", "M"],
    casters: ["RC", "HG"],
    control: ["EXE"],
    footring: ["XF"],
    arm: ["PSAS", "ARS"],
    performance: ["06"],
  },
];
