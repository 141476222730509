import ALL from "./ALL";

const defaultSeries = [
  "X",
  "MVMT",
  "MTCL",
  "AM",
  "BT",
  "EE",
  "EB",
  "FC",
  "FL",
  "HC",
  "HL",
  "Z",
  "GG",
  "UU",
  "KX",
  "WW",
  "RX",
  "RT",
  "TX",
  "CX",
  "GX",
  "Scepter",
];

export default [
  {
    code: "A",
    mvmtCode: "P25",
    name: "A / P25 - Aluminum Base",
    description: `Medium, five-legged, solid cast aluminum base with caster-to-caster diameter of 25.6" and maximum
      width of 24.4". T-cross-section legs ensure cleanability, and endure test loads of 1.5-2x BIFMA strength
      requirements. Features standard 50mm tapered hub (1° 26' 16" (Morse) taper) with gauge line elevation of 113mm
      from the caster insert plane. The base is cast out of UNI EN 46100 aluminum with a robotically polished top
      surface to ensure quality and consistency.`,
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "U", "G", "W", "K", "T", "C", "R", "VF:R"],
    back: ["MB", "TB", "E", "M", "T", "C", "L", "U", "G", "W", "X", "B"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["PM", "T", "E3", "FFAC", "EXE", "S", "P", "T:SLD", "FFAC:SLD"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 169,
      },
      {
        component: "style",
        code: ["foundation"],
        price: 208,
      },
    ],
  },
  {
    code: "W",
    mvmtCode: "P28",
    name: "W / P28 - Wide Alum. Base",
    description: `Large, five-legged, solid cast aluminum base with caster-to-caster diameter of 28.7" and maximum
      width of 27.4". T-cross-section legs ensure cleanability, and endure test loads of 1.5-2x BIFMA strength
      requirements. Features standard 50-mm tapered hub (1° 26' 16" (Morse) taper) with gauge line elevation of
      113mm from the caster insert plane. The base is cast out of UNI EN 46100 aluminum with a robotically polished
      top surface to ensure quality and consistency.`,
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "U", "G", "W", "K", "T", "C", "R", "VF:R"],
    back: ["MB", "TB", "E", "M", "T", "C", "L", "U", "G", "W", "X", "P", "B"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["PM", "E3", "T", "FFAC", "EXE", "T:SLD", "FFAC:SLD", "EXE:SLD", "Z", "S", "P"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 191,
      },
      {
        component: "style",
        code: ["foundation"],
        price: 230,
      },
    ],
  },
  {
    code: "R",
    mvmtCode: "GR",
    name: "R / GR - Reinforced Composite",
    description: `Five-legged composite base with caster-to-caster diameter of 25.6" and maximum width of 24.4".
      T-cross-section legs ensure cleanability, and endure test loads of 1.5-2x BIFMA strength requirements. Features
      standard 50mm, steel-reinforced, tapered hub (1° 26' 16" (Morse) taper) with gauge line elevation of 113mm from
      the caster insert plane. The base is cast out of glass-filled nylon.`,
    seat: ["1", "1F", "1B", "A", "B", "E", "F", "H", "U", "G", "W", "K", "T", "C", "R", "VF:R"],
    back: ALL,
    height: ALL,
    casters: ["RC", "HG"],
    control: ["PM", "E3", "T", "FFAC", "EXE", "Z", "S", "P", "T:SLD", "FFAC:SLD", "EXE:SLD"],
    footring: ALL,
    arm: ["3D", "ARS", "PSAS", "XA"],
    performance: ["ISO6", "C", "06", "WD", "ISO8", "ISO7", "ISO8C", "ISO7C"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: defaultSeries,
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 93,
      },
      {
        component: "style",
        code: ["foundation"],
        price: 97,
      },
    ],
  },
  {
    code: "S",
    mvmtCode: "TUL",
    name: "S / TUL - Steel Tubular",
    description: `Small, five-legged tubular base with caster-to-caster diameter of 23.0" and maximum width of 22.8".
      Welded 1" x 14-gauge steel tubular legs ensure uncompromised strength, withstanding test loads of 2x BIFMA
      strength requirements. Features standard 50mm, steel-reinforced, tapered hub (1° 26' 16" (Morse) taper) with
      gauge line elevation of 203mm from the caster insert plane. Steel tubular ring (tube: 1" by 18-gauge, ring: 21"
      dia.) welded to top of legs. A reinforced nylon insert at the end of each leg adapts the 1" x 14-gauge leg to
      accept a caster or glide stem. The welds at the hub are concealed by a two-piece, polypropylene cover.`,
    seat: ["1F", "1B", "A", "B", "E", "F", "H", "U", "G", "W", "K", "T", "C", "R", "VF:R", "L"],
    back: ["MB", "TB", "E", "M", "T", "C", "L", "U", "G", "W", "X", "B"],
    height: ALL,
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC", "EXE", "S", "P", "L"],
    footring: ["XF"],
    arm: ["3D", "ARS", "PSAS", "XA"],
    performance: ALL,
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: [
      "X",
      "MTHD",
      "AM",
      "BT",
      "EE",
      "EB",
      "FC",
      "FL",
      "HC",
      "HL",
      "Z",
      "GG",
      "UU",
      "KX",
      "WW",
      "RX",
      "RT",
      "TX",
      "CX",
      "GX",
      "Scepter",
    ],
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 210,
      },
      {
        component: "height",
        code: ["L", "M"],
        price: 211,
      },
      {
        component: "height",
        code: ["H"],
        price: 241,
      },
    ],
  },
  {
    code: "TUH",
    name: "TUH - Steel Tubular High",
    description: `Tall, five-legged tubular base with caster-to-caster diameter of 23.0" and maximum width of 22.8".
      Welded 1" x 14-gauge steel tubular legs ensure uncompromised strength, withstanding test loads of 2x BIFMA
      strength requirements. Features standard 50mm, steel-reinforced, tapered hub (1° 26' 16" (Morse) taper) with
      gauge line elevation of 305mm from the caster insert plane. Steel tubular ring (tube: 1" by 18-gauge, ring: 21"
      dia.) welded to top of legs at 12" above floor height. A reinforced nylon insert at the end of each leg adapts
      the 1" x 14-gauge leg to accept a caster or glide stem. The welds at the hub are concealed by a two-piece,
      polypropylene cover. Tall tubular base adds 4" in height over the standard tubular base.`,
    seat: ["1F", "1B"],
    back: ["MB", "TB"],
    height: ["H"],
    casters: ["RC", "RK", "HG"],
    control: ["T", "FFAC"],
    footring: ["XF"],
    arm: ["3D", "XA"],
    performance: [
      "K",
      "ISO6",
      "ISO5",
      "ISO3",
      "ISO6K",
      "ISO5K",
      "ISO3K",
      "C",
      "06",
      "ISO8",
      "ISO8K",
      "ISO7",
      "ISO7K",
      "ISO8C",
      "ISO7C",
    ],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["MTHD"],
    price: [
      {
        component: "style",
        code: ["MVMT"],
        price: 256,
      },
    ],
  },
  {
    code: "G",
    name: 'G - 16" Tubular Steel',
    description: `Sturdy and compact 4-legged tubular steel base with 16" leg spread.`,
    seat: ["T", "C"],
    back: ["X"],
    height: ["L", "M"],
    casters: ["GL"],
    control: ["S"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["06", "C", "ISO4"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["TXG"],
    price: 123,
  },
  {
    code: "C",
    name: "C - Foot Activated Stool",
    description: `This 5-legged cast aluminum base has a 23" leg spread.`,
    seat: ["R", "VF:R"],
    back: ["X"],
    height: ["L", "M"],
    casters: ["CRC"],
    control: ["FA"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["C"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["2A"],
    price: 222,
  },
  {
    code: "A18",
    name: 'A18 - 18" Stool',
    description: `4-legged all-welded construction base made of 0.875" 14-gauge steel tubing; features 16" diameter footring made of 0.75" 19-gauge steel tubing, welded top and bottom; designed for 18" high fixed-leg stool.`,
    seat: ["TX"],
    back: ["X"],
    height: ["L"],
    casters: ["GL"],
    control: ["X"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["ISO4", "C", "06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["AJ"],
    price: 383,
  },
  {
    code: "A24",
    name: 'A24 - 24" Stool',
    description: `4-legged all-welded construction base made of 0.875" 14-gauge steel tubing; features 16" diameter footring made of 0.75" 19-gauge steel tubing, welded top and bottom; designed for 24" high fixed-leg stool.`,
    seat: ["TX"],
    back: ["X"],
    height: ["M"],
    casters: ["GL"],
    control: ["X"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["ISO4", "C", "06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["AJ"],
    price: 393,
  },
  {
    code: "A30",
    name: 'A30 - 30" Stool',
    description: `4-legged all-welded construction base made of 0.875" 14-gauge steel tubing; features 16" diameter footring made of 0.75" 19-gauge steel tubing, welded top and bottom; designed for 30" high fixed-leg stool.`,
    seat: ["TX"],
    back: ["X"],
    height: ["H"],
    casters: ["GL"],
    control: ["X"],
    footring: ["XF"],
    arm: ["XA"],
    performance: ["ISO4", "C", "06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["AJ"],
    price: 407,
  },
  {
    code: "P",
    name: "P - Intensive Plus",
    description: `5-point, star-shaped polished cast aluminum base features robust underside crossbeam construction; 27" diameter.`,
    seat: ["F"],
    back: ["S"],
    height: ["M"],
    casters: ["HG", "IPR"],
    control: ["IP"],
    footring: ["XF"],
    arm: ["ISAS", "XA"],
    performance: ["06"],
    vinylUnderwrap: ALL,
    memoryFoam: ALL,
    series: ["IP"],
    price: 2040,
  },
];
